<template>
  <v-row justify="center">
    <v-dialog v-model="dialog" persistent max-width="400">
      <v-card>
        <v-card-title></v-card-title>
        <v-card-text
          ><v-row justify="center"
            ><v-icon size="60" class="mt-4">mdi-lock</v-icon></v-row
          >
          <div class="text-h6 text-center my-5">
            {{ $t('actions.reset') }} Password
          </div>
          <form v-if="user" @submit.prevent="onSubmit">
            <input hidden autocomplete="username" />
            <v-text-field
              v-model="user.attributes.password"
              outlined
              :placeholder="$t('actions.new') + ' password'"
              autocomplete="new-password"
              :rules="passwordRules"
              :type="showPassword ? 'text' : 'password'"
              :append-icon="showPassword ? 'mdi-eye' : 'mdi-eye-off'"
              @click:append="showPassword = !showPassword"
              @update:error="updateErrors"
            ></v-text-field>
          </form>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn light depressed @click="close">
            {{ $t('actions.cancel') }}
          </v-btn>
          <v-btn
            type="submit"
            color="primary"
            depressed
            :disabled="errors"
            @click="onSubmit"
          >
            {{ $t('actions.save') }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-row>
</template>
<script>
import { mapActions } from 'vuex'
export default {
  props: {
    userId: {
      type: String,
      required: true
    }
  },
  data() {
    return {
      dialog: false,
      errors: true,
      showPassword: false,
      user: null,
      passwordRules: [
        v => !!v || `Password ${this.$t('hints.isRequired')}`,
        v => v.length >= 6 || this.$t('hints.passwordMinLength')
      ]
    }
  },

  watch: {
    $route: {
      immediate: true,
      async handler(route) {
        if (!route.meta.dialog) return
        this.user = await this.fetchUser(this.userId)
        Object.assign(this.user.attributes, { password: '' })
        this.dialog = true
      }
    }
  },
  methods: {
    ...mapActions({ fetchUser: 'user/fetch', updateUser: 'user/update' }),
    close() {
      this.dialog = false
      this.$router.go(-1)
    },
    async onSubmit() {
      await this.updateUser(this.user)
      this.dialog = false
      this.$router.push({ name: 'users' })
    },
    updateErrors(value) {
      this.errors = value
    }
  }
}
</script>
